import React from 'react';
import './Contact.css';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';

export default function Contact() {

    return (
        <div className="Contact-container" id="contact">  
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={4} sm={2} md={4}>
                <ButtonBase>
                  <a href="https://aemail.com/Z3g"><img src={require('../images/new icons/envelope.png')} className="Contact-icon" /></a>
                </ButtonBase>
              </Grid>
              <Grid item xs={6} sm={8} md={6}>
                <h3 className="Contact-text" style={{fontWeight: 'normal'}}>Need a developer? <a className="Contact-link" href="https://aemail.com/Z3g">Get in touch!</a></h3>
              </Grid>
            </Grid>
            <p style={{lineHeight: 5 + 'px'}}>&nbsp;</p>
        </div>
    );
}
