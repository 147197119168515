import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import './About.css';

export default function About() {

  return (
    <div className="About-container" id="about">
        <p style={{lineHeight: 5 + 'px'}}>&nbsp;</p>
        <Grid container spacing={3} justify="center" >
            <Grid item sm={8} xs={12}> 
                <p>I’m a musician-turned-developer and I create intuitive and meaningful experiences on the web. I specialize in fullstack development, content strategy, and project management.</p>
                <p>I write the <a className="Outbound-link" href="https://blog.code-create.dev">Code Create blog</a>, where I explore all things code and design. Read my latest post!</p>
            </Grid>
        </Grid>
        <p style={{lineHeight: 5 + 'px'}}>&nbsp;</p>
        <Grid container spacing={3} justify="center" >
            <Grid item sm={4} xs={12}> 
                <Card className="About-card">
                    <a href="https://blog.code-create.dev/how-to-plan-a-redesign"><CardMedia className="Card-image" image={require('../images/new icons/atom.png')} /></a>
                    <CardContent style={{paddingLeft: 1.5 + 'em', paddingRight: 1.5 + 'em', textAlign: 'center'}}>
                        <p><a className="Outbound-link" href="https://blog.code-create.dev/how-to-plan-a-redesign">How to Plan a Website Redesign</a></p>
                        {/*<p style={{textAlign: 'left'}}>I break down all of the steps for tackling a redesign&mdash;from requirements gathering through deployment.</p>*/}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        <p>&nbsp;</p>
    </div>
    )
}
