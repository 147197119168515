import React from 'react';

//import portfolio components
import './Main.css';
import Contact from './Contact';
import Skills from './Skills';
import About from './About';
import Work from './Work';

//import Material-UI components
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import WorkIcon from '@material-ui/icons/Work';
import CodeIcon from '@material-ui/icons/Code';
import MailIcon from '@material-ui/icons/Mail';
import FaceIcon from '@material-ui/icons/Face';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index} 
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <span>{children}</span>
                </Box>
            )}
        </div>
    );
}

function Main() {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

 return (
    <div className="Main">
        <AppBar position="static" className="Custom-tab">
            <Tabs value={value} 
                TabIndicatorProps={{ 
                    style: { backgroundColor: 'black' }
                }} 
                onChange={handleChange} 
                aria-label="simple tabs portfolio" 
                centered
            >  
                <Tab label="Work" icon={<WorkIcon />} />    
                <Tab label="Skills" icon={<CodeIcon />}/>    
                <Tab label="About" icon={<FaceIcon />}/> 
                <Tab label="Contact" icon={<MailIcon />}/>           
            </Tabs>
        </AppBar>
        
        <TabPanel value={value} index={0}>
           <Work />
        </TabPanel>

        <TabPanel value={value} index={1}>
            <Skills />
        </TabPanel>

        <TabPanel value={value} index={2}>
            <About />
        </TabPanel>

        <TabPanel value={value} index={3}>
            <Contact/>
        </TabPanel>
    </div>
  );
}

export default Main;