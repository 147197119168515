import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import './Work.css';

export default function Work() {

  return (
    <div className="Work-container" id="work">
        <Grid container spacing={2}>
            <Grid item md={4} sm={6} xs={12}>
                <Card className="Work-card">
                    <a href="https://jpshealthnet.org"><CardMedia className="Card-image" image={require('../images/new icons/gears.png')} alt="gears icon" /></a>
                    <CardContent style={{paddingLeft: 1.5 + 'em', paddingRight: 1.5 + 'em'}}>
                        <p><strong><a className="Outbound-link" href="https://jpshealthnet.org">JPS Redesign</a></strong><br />The redesigned website for JPS Health Network</p>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
                <Card className="Work-card">
                    <a href="https://auonlinecs.com"><CardMedia className="Card-image" image={require('../images/new icons/graduation cap.png')} alt="graduation cap icon" /></a>
                    <CardContent style={{paddingLeft: 1.5 + 'em', paddingRight: 1.5 + 'em'}}>
                        <p><strong><a className="Outbound-link" href="https://auonlinecs.com">Auburn Online CS</a></strong><br />The wiki for Auburn U's online CS program</p>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
                <Card className="Work-card">
                    <a href="https://membrq.github.io/advanced-css-portfolio/index.html"><CardMedia className="Card-image" image={require('../images/new icons/airplane.png')} alt="airplane icon" /></a>
                    <CardContent style={{paddingLeft: 1.5 + 'em', paddingRight: 1.5 + 'em'}}>
                        <p><strong><a className="Outbound-link" href="https://membrq.github.io/advanced-css-portfolio/index.html">Natours</a></strong><br />A UI design project for an adventure travel company</p>
                    </CardContent>
                </Card>
            </Grid>
        </Grid> 
    </div>
    )
}
