import React, { Component } from 'react';
import { render } from 'react-dom';
import Typed from 'react-typed';
import 'react-typed/dist/animatedCursor.css';
import './AnimatedText.css';

class AnimatedText extends Component {
    render() {
        return (
            <div>
             <code style={{fontWeight: 'bold'}}>
                <Typed 
                    strings={[">: hi, i'm marie-elise"]}
                    typeSpeed={50}
                    cursorChar={'_'}
                />
            </code>
            <br />
            <code>
                <Typed 
                    strings={[">: i'm a software engineer"]}
                    typeSpeed={50}
                    cursorChar={'_'}
                    startDelay={2000}
                />
            </code><br />
            <code>
                <Typed 
                    strings={[">: i love to plan", ">: i love to design", ">: i love to code"]}
                    typeSpeed={50}
                    backSpeed={40}

                    cursorChar={'_'}
                    startDelay={4000}
                />
            </code><br />
            {/*<code>>: i love to plan | i love to design | i love to code<span class="typed-cursor typed-cursor--blink">_</span></code>*/}
            </div>
        );
    }
}

export default AnimatedText;

