import React from 'react';
import Grid from '@material-ui/core/Grid';
import './Skills.css';


export default function Skills() {

  return (
    <div className="Skills-container" id="skills">
        <Grid container spacing={3}>
            <Grid container xs={6}>
                <p>&nbsp;</p>
            </Grid>
            <Grid container xs={6}>
                <p className="Skills-text"><span style={{fontWeight: 900}}>Frontend</span><br />HTML, CSS, Sass, JavaScript, Bootstrap, ReactJS</p>
            </Grid>
        </Grid>
        <Grid container spacing={3}>
            <Grid container xs={3} justify="flex-start" alignItems="center" style={{textAlign: 'right'}}>
                <p className="Skills-text"><span style={{fontWeight: 900}}>Platforms</span><br />Drupal, WordPress, AWS</p>
            </Grid>
            <Grid container xs={6} justify="flex-start" alignItems="flex-start">
                <img alt="left-brain right-brain icon" src={require('../images/new icons/brain.png')} style={{width: 100 + '%'}}  />
            </Grid>
            <Grid container xs={3} justify="flex-start" alignItems="center">
                <p className="Skills-text"><span style={{fontWeight: 900}}>Tools</span><br />Git, XD, Illustrator</p>
            </Grid>
        </Grid>
        <Grid container spacing={3}>
            <Grid container xs={6} className="Backend-container" justify="flex-end">
                <p className="Skills-text" style={{textAlign: 'right'}}><span style={{fontWeight: 900}}>Backend</span><br />Ruby on Rails, Java, Python, NodeJS, SQL</p>
            </Grid>
            <Grid container xs={6}>
                <p>&nbsp;</p>
            </Grid>
        </Grid>
    </div>
    )
}
