import React from 'react';
import Menu from './components/Menu';
import Main from './components/Main';
import './App.css';
import AnimatedText from './components/AnimatedText';


function App() {
  return (
    <div className="App">
      <Menu />
      <header className="App-header">
        <div className="Intro-title Intro-typed" style={{marginTop: 9 + 'em'}}>
          <AnimatedText/>
        </div>
      </header>
      <Main />
      <footer className="App-footer">
          <div className="Footer-content">
            <span>&copy; Marie-Elise, {(new Date().getFullYear())}<br /><br /></span>
            <span style={{paddingRight: 1 + 'em'}}>
              <a href="https://dev.to/kreyoldev">
                <img src="https://d2fltix0v2e0sb.cloudfront.net/dev-badge.svg" alt="Marie-Elise's DEV Profile" height="30" width="30" style={{marginBottom: .1 + 'em'}} />
              </a>
            </span>
            <span style={{paddingRight: 1 + 'em'}}> 
              <a href="https://github.com/membrq">
                <svg width={35} height={35} viewBox="0 0 24 24">
                  <path fill="#000000" d="M12,2A10,10 0 0,0 2,12C2,16.42 4.87,20.17 8.84,21.5C9.34,21.58 9.5,21.27 9.5,21C9.5,20.77 9.5,20.14 9.5,19.31C6.73,19.91 6.14,17.97 6.14,17.97C5.68,16.81 5.03,16.5 5.03,16.5C4.12,15.88 5.1,15.9 5.1,15.9C6.1,15.97 6.63,16.93 6.63,16.93C7.5,18.45 8.97,18 9.54,17.76C9.63,17.11 9.89,16.67 10.17,16.42C7.95,16.17 5.62,15.31 5.62,11.5C5.62,10.39 6,9.5 6.65,8.79C6.55,8.54 6.2,7.5 6.75,6.15C6.75,6.15 7.59,5.88 9.5,7.17C10.29,6.95 11.15,6.84 12,6.84C12.85,6.84 13.71,6.95 14.5,7.17C16.41,5.88 17.25,6.15 17.25,6.15C17.8,7.5 17.45,8.54 17.35,8.79C18,9.5 18.38,10.39 18.38,11.5C18.38,15.32 16.04,16.16 13.81,16.41C14.17,16.72 14.5,17.33 14.5,18.26C14.5,19.6 14.5,20.68 14.5,21C14.5,21.27 14.66,21.59 15.17,21.5C19.14,20.16 22,16.42 22,12A10,10 0 0,0 12,2Z" />
                </svg>
              </a>
            </span>
            <span>
              <a href="https://blog.marie-elise.dev">
                <svg width="28" height="28" viewBox="0 0 337 337" fill="none" style={{marginBottom: .15 + 'em'}}><path fillRule="evenodd" clipRule="evenodd" d="M23.155 112.598c-30.873 30.874-30.873 80.93 0 111.804l89.443 89.443c30.874 30.873 80.93 30.873 111.804 0l89.443-89.443c30.873-30.874 30.873-80.93 0-111.804l-89.443-89.443c-30.874-30.873-80.93-30.873-111.804 0l-89.443 89.443zm184.476 95.033c21.612-21.611 21.612-56.651 0-78.262-21.611-21.612-56.651-21.612-78.262 0-21.612 21.611-21.612 56.651 0 78.262 21.611 21.612 56.651 21.612 78.262 0z" fill="black"/></svg>
              </a>
            </span>
          </div>
      </footer>
    </div>
  );
}

export default App;
